<template>
  <vx-card title="Sliders versión móvil">
    <p>Aquí se pueden modificar los Sliders que aparecen en el Home.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <!-- Sliders -->
      <div class="flex flex-wrap -mx-2">
        <div class="w-1/3 px-2" v-for="(slider, index) in content.mobileSliders" :key="index">
          <div>
            <!-- Image Container -->
            <div class="img-container flex items-center justify-center">
              <img :src="slider.image" alt="img" class="responsive">

            </div>
            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" :id="`slider${index}`"
                     @change="updateSlider($event,slider)"
                     accept="image/*">
              <span style="cursor: pointer" class="ml-2" type="flat"
                    @click="changeSlider(`slider${index}`)">Cambiar
                                </span>
              <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                    @click="deleteImage(index)">Eliminar</span>
            </div>
          </div>
          <div class="mt-5">
            <vs-input @blur="slider.path= trimString(slider.path)" label="Path" v-model="slider.path"
                      class="mt-5 w-full" name="path"
                      v-validate="'required'"/>
          </div>
          <hr class="mt-5 mb-5">
        </div>
      </div>

      <!--Add image-->
      <vs-button style="width: 100%" @click="addSlider">Agregar slider(800x600)</vs-button>
      <!--End-->

      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import trimString from '../mixins/trimString'

import { db, auth, FieldValue, storage } from '@/firebase/firebaseConfig'

export default {
  name: 'MobileSliders',
  mixins: [
    trimString
  ],
  data () {
    return {
      content: {
        mobileSliders: []
      },
      progress: false,
      initProgress: false
    }
  },
  /**
   * Get data of firestore
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.initProgress = true
      const querySnapshot = await db.collection('content').get()
      querySnapshot.forEach((doc) => {
        this.content.id = doc.id
        this.content.mobileSliders = doc.data().mobileSliders || []
      })
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  computed: {
    isFormValid () {
      return !this.errors.any()
    }
  },
  methods: {
    /**
     * Save data in firestore
     */
    async submitData () {
      try {
        const result = this.$validator.validateAll()
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          // Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          await contentRef.update({
            ...cloneContent,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })

          this.$vs.notify({
            color: 'success',
            title: 'Sliders',
            text: 'Sliders modificados correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Save images in firebase storage
     * @param image
     * @returns {Promise<unknown>}
     */
    submitImages (image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = storage.ref(`content/sliders/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    async updateSlider (input, slider) {
      this.progress = true
      if (input.target.files && input.target.files[0]) {
        slider.image = await this.submitImages(input.target.files[0])
      }
      this.progress = false
    },
    deleteImage (index) {
      this.content.mobileSliders.splice(index, 1)
    },
    changeSlider (id) {
      const slider = document.getElementById(id)
      slider.click()
    },
    addSlider () {
      let obj = {
        path: '/',
        image: 'https://via.placeholder.com/800x600'
      }
      this.content.mobileSliders.push(obj)
    }
  }
}
</script>
